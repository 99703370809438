import './bootstrap';
import '@css/var.css'; // Global

let authModule = (function() {
    var togglePasswordVisibility = function (passwordField) {
        if (passwordField.type === "password") {
            passwordField.type = "text";
            return;
        }

        passwordField.type = "password";
    }

    var bindPasswordControlEventHandlers = function () {
        var passwordToggleControls = document.querySelectorAll('.passwordToggle');
        passwordToggleControls.forEach(toggleCtrl => {
            toggleCtrl.addEventListener('click', function (event) {
                let passwordFieldName = toggleCtrl.getAttribute('for');
                let passwordFields = document.getElementsByName(passwordFieldName);
                passwordFields.forEach(field => togglePasswordVisibility(field));
            });
        });
    }

    return {
        init: function () {
            bindPasswordControlEventHandlers();
        }
    }
})();

authModule.init();
